.user-management-container{
  .user-admin {
      .search {
        .search-icon {
          background: url('/assets/icons/gizmo-search.svg') no-repeat;
          background-size: 1.7rem auto;
          background-position: center;
          width: 2rem;
          border-right: none;
        }
        .form-control {
          border-left: none;
        }
      }
    }
    
    .user-tbl{
      td{
        height:40px;
        vertical-align:middle !important;
        
        .permission-cnt{
          cursor: pointer;
          color:#007398;;
        }
      }
    }
}