@import "../../index";

.znr-login {
  height: 39rem;
  background-color: theme-color("grey-dark");
  margin: 0 -1rem;
  border: 1px solid theme-color("grey-dark");
  .znr-login-background {
    background-image: url(/assets/images/earth_heartbeat.jpg);
    opacity: 0.8;
    height: 100%;
    width: 100%;
  }
  .znr-login-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      width: 20rem;
      padding: 2.5rem;
      button {
        margin-top: 1rem;
      }
      .auth0-logo {
        height: 3.75rem;
        margin: 0 auto;
      }
      h3 {
        margin: 1.5rem 0 1rem;
        text-align: center;
      }
    }
  }
  .loading {
    width: 2rem;
    height: 2rem;
  }
}
