.pin-import-container{
    margin-top:32px;
    a,.anchor-link{color:#007398;cursor: pointer;}
    .file-upload-container{
        
        font-family: 'Nexus Sans Pro', sans-serif;
        font-size: 13px;
        .resourceId{
            
        }
        .file-upload-div{
            height: 148px;
            width:204px;
            border: 1px dashed #b9b9b9;
            display: flex2;
            flex-direction: column;
            float: left;
            margin-right:38px ;
            button{ 
                font-size:13px !important;
                background-color: #ffffff;
                color:#343a40 ; 
                border: 2px solid #007398;
                width:138px;
                height:32px;
                margin:auto;
            }
            
            .uploader {
                border-color: transparent;
                border-style: solid;
                border-width: 0 0 100px 200px;
                cursor: pointer;
                direction: ltr;
                margin: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
             
            .upload-btn {
                font-size:13px !important;
                background-color: #ffffff;
                color:#343a40 ; 
                border: 2px solid #007398;
                width:138px;
                height:32px;
                margin:auto;
                text-align:center;
            }
            .fileinput-button {
                position: relative;
                overflow: hidden;
                z-index: 99;
            }
            .file-name{
                font-size: 14px;
                color: #00000082;
                word-break: break-word;
            }
        }
        .file-instruction{
            font-size:13px;
            height: 148px;
            margin-left:100px !important;
            padding:10px;
            img{
                margin-right:5px;
            }
            .instruction-logo{
                float:left;
                height:50px;
            }
        }
        
        .importBtn{
            font-size:14px !important;
            background-color: #007398 !important;
            color:#ffffff !important; 
            font-family: 'Nexus Sans Pro', sans-serif;
            height:33px;
            margin-top: 12px;
        }
        
        .block {
            height: 148px;
            width:635px;
            /* dashed border */
            padding:20px;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
         }
         

    }

    .bg-error{
        background-color:  rgba(255, 106, 90, 0.3);
    }

    .bg-valid{
        background-color:  rgba(192, 242, 93, 0.3);
    }
    
    .import-summary-container{
        width:624px;
        padding:10px;
        margin-top:35px;
        .summary-div{
            /*width:425px;
            height:129px;*/
            /*margin-top:16px;*/
            /*background-color:  #fff0e4;*/
            /*padding:10px;*/
            /*padding-bottom: 130px;*/
            border-radius: 10px;
            .summaryCount{
                font-family: 'Nexus Sans Pro Bold', 'Nexus Sans Pro', sans-serif;
                font-weight: 700;
                color: #333333;
                font-size:13px;
                display:inline-block;
                width:36px;
                text-align:right;
            }
            .summaryReport{
                color: #333333;
                font-size:13px;
                font-family: 'Nexus Sans Pro', sans-serif;
                margin-left:8px;
            }
        }

        .import-summary{
            /*margin-top:35px;*/
            font-family: 'Nexus Sans Pro Bold', 'Nexus Sans Pro', sans-serif;
            color: #333333;
            font-size:13px;
        }
        
    }


    //modal css
    .modal1{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1000; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgba(0, 0, 0, 0.04); /* Black w/ opacity */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(220, 220, 220, 0.43); /* Black w/ opacity */
        font-family: 'Nexus Sans Pro', sans-serif;
        
        
        .modal-content1 {   
            margin: auto;
            position: relative;
            width: 512px;
            height:145px;
            border-top: 1px solid#ff6c00;
            outline: 16px solid #dcdcdc;

            font-size:14px;
            color:#505050;
            background-color: #ffffff;
            
            margin-top: 100px;
            
            .modal-body1{
                position: absolute;
                left:16px;
                right:10px;
                top:16px;
                .modal-title1{
                    font-family: 'Nexus Sans Pro Bold', 'Nexus Sans Pro', sans-serif;
                    font-weight: 700;
                    color: #505050;
                    .rotate{
                        animation: rotation 2s infinite linear;
                    }
                    img{
                        margin-bottom:1px;
                    }
                    
                    
                    @keyframes rotation {
                        from {
                          transform: rotate(0deg);
                        }
                        to {
                          transform: rotate(350deg);
                        }
                      }
                }
                
                .popup-msg{
                    color:#2E2E2E;
                    font-size: 13px;
                    height: 30px;
                }
                .modal-btn{
                    text-align: right;
                    .replaceBtn{
                        /*@extend .submitBtn;*/
                        height:32px;
                        border: 2px solid #007398;
                        padding:4px;
                        margin-left:10px;
                    }
                    .replaceBtn:hover{
                        /*@extend .submitBtn:hover;*/
                    }
                }
                
                /* The Close Button */
                .close {
                    color: #000;
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                    }
                    
                    .close:hover,
                    .close:focus {
                    color: #000;
                    text-decoration: none;
                    cursor: pointer;
                    }
                }
        }
            
    }
}

