
@import "../../index";
@import "../../../../node_modules/bootstrap/scss/nav";

.znr-top-menu {
  font-size: 1.1rem;
  .znr-top-menu__nav {
    border-bottom: 1px solid theme-color("grey-dark");
    .link-item {
      font-size: 1.15rem;
      line-height: 1.25rem;
      height: 100%;
      padding-bottom: .25rem;
      border-bottom: .12rem solid white;
      &:not(:first-child) {
        margin-left: 2rem;
      }
      &:hover {
        border-bottom-color: theme-color("primary");
        transition: border-bottom-color 74ms ease-out;
      }
      span, a, a:hover {
        text-decoration: none;
        color: theme-color("grey-dark");
      }
    }
  }
  .znr-top-menu__details {
    font-size: 1rem;
    a, a:hover {
      text-decoration: none;
      color: theme-color("grey-dark");
      display: block;
    }
    a:hover {
      color: theme-color("primary");
    }
    span {
      display: block;
    }
    .level-1 {
      font-size: 1rem;
      max-width: 90%;
      .text {
        font-weight: normal;
      }
      &.title {
        border-bottom: 1px solid theme-color("grey-dark");
        font-weight: bold;
      }
    }
    .level-2, .level-3, .level-4 {
      margin: 0;
      font-size: 1rem;
      max-width: 90%;
    }
    .level-1, .level-2, .level-3, .level-4 {
      padding: ($spacer * 1) 0 0 ($spacer * 1.8);
      &.with-icon {
        padding-left: 0;
      }
    }
  }
}
.menu-info, .menu-icon {
  padding: 0;
  margin: 0;
  height: 1rem;
  margin-top: -0.2rem;
  margin-left: 0.3rem;
}
.menu-icon {
  height: 1.5rem;
  padding-right: .3rem;
  margin-left: 0;
}
