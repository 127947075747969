.system-status {
  .up-txt {
    font-size: 3rem;
    font-weight: bold;
  }
  .total-txt {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--light);
  }
  .card {
    width: 21.8%;
    margin-right: 3rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    &:hover {
      box-shadow: 0px 1px 15px -4px var(--light);
    }
    &.last {
      margin-right: 0;
    }
    .card-header {
      font-size: 1.2rem;
      border: none;
      text-transform: uppercase;
      color: var(--light);
    }
    .card-footer.status {
      padding: 0;
      height: 2.4rem;
      border: none;
      overflow: hidden;
      .btn {
        width: 100%;
        position: relative;
        bottom: -1.68rem;
        &.bg-red {
          background-color: var(--danger);
        }
        &.bg-green {
          background-color: var(--success);
        }
        &.bg-yellow {
          background-color: var(--warning);
        }
      }
    }
    &:hover {
      .card-footer.status {
        .btn {
          bottom: 0;
          transition: bottom 0.2s;
        }
      }
    }
  }

  &.unavailable {
    .title {
      font-weight: bold;
      width: 11rem;
      display: inline-block;
    }
  }
  .znr-system-status-link {
    height: 1.5rem;
    margin-right: 0.3rem;
  }
}
