@font-face {
  font-family: NexusSerif;
  src: url('/assets/fonts/NexusSansWebPro.woff') format("woff");
}
@font-face {
  font-family: NexusSerif;
  font-weight: bold;
  src: url("/assets/fonts/NexusSansWebPro-Bold.woff") format("woff");
}

@font-face {
  font-family: NexusSerif;
  font-style: italic;
  src: url("/assets/fonts/NexusSansWebPro-Italic.woff") format("woff");
}

@font-face {
  font-family: NexusSerif;
  font-style: italic;
  font-weight: bold;
  src: url("/assets/fonts/NexusSansWebPro-BoldItalic.woff") format("woff");
}

$body-color: #53565A;
$font-family-sans-serif: NexusSerif, sans-serif;
$theme-colors: (
  "primary": #FF6C00,
  "secondary": #007398,
  "grey-dark": #53565A,
  "success": #8ED700,
  "danger": #FF5252,
  "warning": #FFC308,
  "light": #A7B1C1
);
$enable-rounded:   false;

// bootstrap inclusions
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// Optional bootstrap inclusions
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/pagination";

//zener overwrites
@import "./styles/index";
