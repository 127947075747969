.aticle-exists{
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
    margin-left:4px;
    margin-right:2px;
}
.aticle-no-exists{
    @extend .aticle-exists;
}
.td-break{
    word-wrap: break-word;
    word-break: break-all;
}
.td-unbreak{
    white-space:nowrap;
}
.ingestion-tbl {
    border-bottom: 1px solid #2e2e2e !important;
    border-top: 1px solid #2e2e2e !important;
}
.ingestion-tbl tr:first-child td {
    border-top: 1px solid #2e2e2e !important; 
}
.ingestion-tbl tr{
    vertical-align: middle;
}
.ingestion-tbl td, .ingestion-tbl th{
    font-size:13px;
    font-family: 'Nexus Sans Pro', sans-serif;
    color:#505050;
    height: 32px;
}
.ingestion-tbl td{
    border-top: 1px solid #b9b9b9 !important; 
}

.pointer{
    cursor:default;
}

.ingestion-tbl th{
    font-weight: 100;
    @extend .td-unbreak;
}

.pii-number{
    font-size: 13px;
    font-family: 'Nexus Sans Pro', sans-serif;
    color:#2E2E2E;
}
.article-absent-font{
    @extend .pii-number;
    color:#f73e29;
}

.article-present-font{
    @extend .pii-number;
    color:#8ed700;
}

.submitBtn{
    font-size:14px !important;
    background-color: #007398 !important;
    color:#ffffff !important; 
    font-family: 'Nexus Sans Pro', sans-serif;
    height:33px;
}
.submitBtn:hover{
    color:#343a40  !important;
    background-color: #ffffff !important;
    border: 2px solid rgba(255, 108, 0, 1);
}
.searchBtn{
    font-size:14px !important;
    background-color: #ffffff !important;
    color:#343a40  !important; 
    font-family: 'Nexus Sans Pro', sans-serif;
    border: 2px solid #007398 !important;
    height:33px;
}
.searchBtn:hover{
    border: 2px solid rgba(255, 108, 0, 1) !important;
}
input[name='PII']{
    border: 1px solid #797979;
    height:32px;
}
.no-indgestion{
  font-family: 'Nexus Sans Pro Italic', 'Nexus Sans Pro Regular', 'Nexus Sans Pro', sans-serif;
  font-style: italic;
  color: #505050;
  vertical-align: middle !important;
}




.PII-data{
    opacity:1;
}

//modal css
.modal1{
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.04); /* Black w/ opacity */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(220, 220, 220, 0.43); /* Black w/ opacity */
    font-family: 'Nexus Sans Pro', sans-serif;
    
    
    .modal-content1 {   
        margin: auto;
        position: relative;
        width: 512px;
        height:145px;
        border-top: 1px solid#ff6c00;
        outline: 16px solid #dcdcdc;

        font-size:14px;
        color:#505050;
        background-color: #ffffff;
        
        margin-top: 100px;
        
        .modal-body1{
            position: absolute;
            left:16px;
            right:10px;
            top:16px;
            .popup-msg{
                color:#2E2E2E;
                font-size: 13px;
                height: 30px;
            }
            .modal-btn{
                text-align: right;
                .cancel-and-view-inge {
                    background-color: rgba(255, 255, 255, 0);
                    box-sizing: border-box;
                    text-decoration: underline;
                    color: #2e2e2e;
                    line-height: normal;
                    cursor: pointer;
                  }
                  .replaceBtn{
                      @extend .submitBtn;
                      height:32px;
                      border: 2px solid #007398;
                      padding:4px;
                      margin-left:10px;
                  }
                  .replaceBtn:hover{
                    @extend .submitBtn:hover;
                  }
            }
            
            /* The Close Button */
            .close {
                color: #000;
                float: right;
                font-size: 28px;
                font-weight: bold;
                }
                
                .close:hover,
                .close:focus {
                color: #000;
                text-decoration: none;
                cursor: pointer;
                }
            }
    }
        
}
  
  