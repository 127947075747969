.znr-footer {
  img.znr-logo-elsevier {
    width: 5rem;
  }
  img.znr-logo-relx {
    width: 10rem;
  }
  .footer-row {
    border-top: .125rem solid white;
  }
}
