table.pin-list {
  margin-bottom: 0;
  th, td {
    width: 13%;
    word-break: break-all;
    &.publication {
      width: 16%;
    }
    &.status {
      width: 6%;
    }
    &.chk {
      input {
        margin-left: 0;
        padding-left: 0;
      }
      width: 2%;
    }
  }
}

.pin-filter {
  height: 2.46rem;
  .input-group-append, .input-group-prepend {
    max-height: 97%;
  }
}
.pin-selected-filter {
  button.close {
    margin-bottom: 0.23rem;
    margin-left: 0.25rem;
  }
  .pin-filter__tag-header {
    font-weight: bold;
  }
}

.pin-controls {
  margin-top: -2px;
}
