.ac-ingest-container{
    .btn:focus {
        outline: none;
        box-shadow: none;
    }
    margin-top:32px;
    a,.anchor-link{color:#007398;cursor: pointer;}
    .file-upload-container{
        
        font-family: 'Nexus Sans Pro', sans-serif;
        font-size: 13px;
        .audience-div{
            margin-bottom: 10px;
        }
        .label-audience{
            color: #007398;
            font-size: 12px;
            margin-left: 5px;
            margin-bottom: 1px;
        }
         svg{
            bottom: 4px;
            fill: currentColor;
            pointer-events: none;
            position: absolute;
            right: 0;
            z-index: 1;
            color:#007398
        }
        .audience-type{
            width:225px;
            height:32px;
            font-family: 'Nexus Sans Pro', sans-serif;
            color: #2e2e2e;
            font-size:13px;
            border-width: 0 0 2px;
            padding: 8px 0px;
            border-color: #737373;
            /*-webkit-appearance: none;*/ 
        }
        .audience-type:focus{
            border-color: #737373;  box-shadow: none; -webkit-box-shadow: none;
        }
        .audience-type option{
            font-size:14px;
            padding:50px 100px;
            color: #2e2e2e;
        }


        .file-error{
            margin-top:10px;
        }
        small{
            font-size: 12px;
            
        }
        
        .file-upload-div{
            min-height: 49px;
            width:352px;
            border: 1px dashed #b9b9b9;
            display: flex2;
            flex-direction: column;
            float: left;
            margin-right:7px ;
            padding:7px;
            button{ 
                font-size:13px !important;
                background-color: #ffffff;
                color:#343a40 ; 
                border: 2px solid #007398;
                width:138px;
                height:32px;
                margin:auto;
            }
            
            .uploader {
                border-color: transparent;
                border-style: solid;
                border-width: 0 0 100px 200px;
                cursor: pointer;
                direction: ltr;
                margin: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
             
            .upload-btn {
                font-size:13px !important;
                background-color: #ffffff;
                color:#343a40 ; 
                border: 2px solid #007398;
                width:138px;
                height:32px;
                margin:auto;
                text-align:center;
            }
            .upload-btn:hover{
                border: 2px solid rgba(255, 108, 0, 1);
            }
            .fileinput-button {
                position: relative;
                overflow: hidden;
                z-index: 99;
            }
            .file-name{
                font-size: 12px;
                color:  #007398;
                font-family: 'Nexus Sans Pro Italic', 'Nexus Sans Pro Regular', 'Nexus Sans Pro', sans-serif;
                font-style: italic;
            }
        }
        
        .importBtn{
            font-size:14px !important;
            background-color: #007398 !important;
            color:#ffffff !important; 
            font-family: 'Nexus Sans Pro', sans-serif;
            height:33px;
            margin-top: 8px;
        }
        .importBtn:hover{
            color:#343a40  !important;
            border: 2px solid rgba(255, 108, 0, 1);
            background-color: #ffffff !important;
        }

        .ingestBtn{
            @extend .importBtn;
            margin-top:43px;
        }
        
        .ingestBtn:hover{
            color:#343a40  !important;
            border: 2px solid rgba(255, 108, 0, 1);
            background-color: #ffffff !important;
        }


        .file-instruction{
            color: #007398;
            font-family: 'Nexus Sans Pro', sans-serif;
            font-size:12px;
          }

        //popover instruction
        .popover__title {
            font-size: 24px;
            line-height: 36px;
            text-decoration: none;
            color: rgb(228, 68, 68);
            margin-bottom: 1px;
            cursor: pointer;
          }
  
          .popover__wrapper {
            position: relative;
            margin-top: 1.5rem;
            margin-bottom: 7px;
            display: inline-block;
          }
          .popover__content {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left : 270px;
            transform: translate(0, 10px);
            padding: 5px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            width: 352px;
            height:51px;



            background-color: #ffffff;
            outline: 16px solid #dcdcdc;
            border-top: 1px solid #ff6c00;

            
            
            .modal-content1 {   
               font-family: 'Nexus Sans Pro', sans-serif;
               color: #333333;
               font-size:13px;
            }

          }
          .popover__content:before {
            position: absolute;
            z-index: -1;
            content: "";
            right: calc(50% - 10px);
            top: -12px;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #bfbfbf transparent;
            transition-duration: 0.3s;
            
          }
          .popover__wrapper:hover .popover__content {
            z-index: 10;
            opacity: 1;
            visibility: visible;
            transform: translate(-248px, -61px);
          }

    }

    .bg-error{
        background-color:  rgba(255, 106, 90, 0.3);
    }

    .import-summary-container{
        width:624px;
        padding:10px;
        margin-top:35px;
        .summary-div{
            /*width:425px;
            height:129px;*/
            /*margin-top:16px;*/
            /*background-color:  #fff0e4;*/
            /*padding:10px;*/
            /*padding-bottom: 130px;*/
            border-radius: 10px;
            .summaryCount{
                font-family: 'Nexus Sans Pro Bold', 'Nexus Sans Pro', sans-serif;
                font-weight: 700;
                color: #333333;
                font-size:13px;
                display:inline-block;
                width:36px;
                text-align:right;
            }
            .summaryReport{
                color: #333333;
                font-size:13px;
                font-family: 'Nexus Sans Pro', sans-serif;
            }
        }

        .import-summary{
            /*margin-top:35px;*/
            font-family: 'Nexus Sans Pro Bold', 'Nexus Sans Pro', sans-serif;
            color: #333333;
            font-size:13px;
        }
        
    }


    //modal css
    .modal1{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1000; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgba(0, 0, 0, 0.04); /* Black w/ opacity */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(220, 220, 220, 0.43); /* Black w/ opacity */
        font-family: 'Nexus Sans Pro', sans-serif;
        
        
        .modal-content1 {   
            margin: auto;
            position: relative;
            width: 512px;
            height:145px;
            border-top: 1px solid#ff6c00;
            outline: 16px solid #dcdcdc;

            font-size:14px;
            color:#505050;
            background-color: #ffffff;
            
            margin-top: 100px;
            
            .modal-body1{
                position: absolute;
                left:16px;
                right:10px;
                top:16px;
                .modal-title1{
                    font-family: 'Nexus Sans Pro Bold', 'Nexus Sans Pro', sans-serif;
                    font-weight: 700;
                    color: #505050;
                    .rotate{
                        animation: rotation 2s infinite linear;
                    }
                    img{
                        margin-bottom:1px;
                    }
                    
                    
                    @keyframes rotation {
                        from {
                          transform: rotate(0deg);
                        }
                        to {
                          transform: rotate(350deg);
                        }
                      }
                }
                
                .popup-msg{
                    color:#2E2E2E;
                    font-size: 13px;
                    height: 30px;
                }
                .modal-btn{
                    text-align: right;
                    .replaceBtn{
                        /*@extend .submitBtn;*/
                        height:32px;
                        border: 2px solid #007398;
                        padding:4px;
                        margin-left:10px;
                    }
                    .replaceBtn:hover{
                        border: 2px solid rgba(255, 108, 0, 1);
                    }
                }
                
                /* The Close Button */
                .close {
                    color: #000;
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                    }
                    
                    .close:hover,
                    .close:focus {
                    color: #000;
                    text-decoration: none;
                    cursor: pointer;
                    }
                }
        }
            
    }
}

