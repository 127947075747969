.add-permission-container {
  font-family: sans-serif;
  color: #212529b3;
  font-size: 14px;

  .user-information {
    padding: 14px;
    background-color: #f5f5f5;
    font-size: 13px;
    font-weight: 400;
    margin-top: 9px;
    .data {
      color: #343a40f0;
      font-weight: 600;
    }
    .verified-img{
        width:19px;
        height:19px;
        margin-left:10px;
    }
  }

  .add-permission {
    height:117px;
    color: #333333;
    padding: 14px;
    background-color: rgba(220, 220, 221, 0.3);
    font-size: 14px;
    border: 1px solid #dcdcdd;
    #add-permissions-label {
      margin-bottom: 5px;
    }

    .search-btn {
      width: 145px;
      height: 40px;
      background-color: rgba(0, 115, 152, 1);
      border: none;
      border-radius: 2px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: #ffffff;
    }
  }

  .search {
    .ISBN-search,
    .Publication-search,
    .Author-search {
      border-right: none;
      font-size: 13px;
      color: #797979;
      height: 40px;
    }
  }
  .search .search-icon {
    background: url(/assets/icons/gizmo-search.svg) no-repeat;
    background-size: 1.2rem auto;
    background-position: center;
    width: 2rem;
    border-left: none;
    background-color: #ffffff;
  }
  .entitlement-tbl {
    th {
      color: #495057e8;
      font-size: 13px;
      height: 30px;
    }
  
    td {
        font-size: 13px;
        height: 40px;
        vertical-align: middle !important;
        color: #53565a;
        font-weight: 100;
        .entitlement-active {
        width: 87px;
        height: 20px;
        background-color: #4cd461;
        box-sizing: border-box;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        }
        .entitlement-inactive {
        @extend .entitlement-active;
        background-color: #ff5252;
        }
        .dot{
            font-size:18px;
        }
        .pointer-show{
            cursor: pointer;
        }
    }
    tbody tr:nth-child(odd) {
        background-color: #f6f6f7;
    }
}
  .activate-deactivate {
    height: 40px;
    padding: 9px 10px 10px 16px;
    background-color: rgba(242, 242, 242, 0.5);
    .activate-btn {
      color: #007398;
      font-weight: 600;
      cursor:pointer;
    }
    .deactivate-btn {
      @extend .activate-btn;
      margin-left: 40px;
    }
  }
}
