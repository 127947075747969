.sticky.bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 999
}

.sticky.top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}
